import React, {Component} from "react"
import {Link, graphql} from "gatsby"
import Img from "gatsby-image"
import arrow from "../images/arrow.png"
import {Motion, spring} from 'react-motion'
import Layout from "../layouts"
import FacebookProvider, {Share} from 'react-facebook';
import ShareLink from 'react-twitter-share-link';

const img1 = require('../images/android-icon-192x192.png');
const img2 = require('../images/favicon-32x32.png');
const img3 = require('../images/favicon-96x96.png');
const img4 = require('../images/favicon-16x16.png');


class Projects extends Component {
    render() {
        const data = this.props.data
        const {group, index, first, last, pageCount} = this.props.pathContext;

        console.log(group, index, first, last, pageCount);


        const projects = group.map((e, i) => {
            return <article key={i} className={"col-6 col-sm-12 projects__project"}>
                <div className="projects__content">
                    <h2 className="projects__title">
                        {e.node.title}
                    </h2>
                    <dl className={"projects__dl"}>
                        <dt>Klient:</dt>
                        <dd>{e.node.acf && e.node.acf.client}</dd>
                        <dt>www:</dt>
                        <dd>{e.node.acf && e.node.acf.www}</dd>
                        <dt>Opis:</dt>
                        <dd>{e.node.acf && e.node.acf.description}</dd>
                    </dl>
                    <Link  activeStyle={{color: "#0033cc"}}  to={`/project/${e.node.slug}`} className="projects__link">
                        <img src={arrow} alt=""/>
                    </Link>
                </div>
                {
                    e.node.featured_media &&
                    <img className={"projects__thumb"}
                         src={e.node.featured_media.source_url} alt=""/>
                }
            </article>
        });

        if(typeof window === 'undefined') { return null; } let localization = window.location.pathname.split('/');

        let langs = ['pl', 'en', 'de'].map((element, index) => {

            return <li key={index}>
                {
                    element === 'pl' ?
                        <Link  activeStyle={{color: "#0033cc"}}  to={`/projekty`}>{element}</Link> :
                        <Link  activeStyle={{color: "#0033cc"}}  to={`/${element}/projekty`}>{element}</Link>
                }

            </li>
        });


        return (
            <Layout>
                {/* <div className="grid">
                    <ul className="langs col-12">
                        {langs}
                    </ul>
                </div> */}
                <Motion defaultStyle={{
                    x: 120,
                }} style={{
                    x: spring(0, {stiffness: 255, damping: 20}),
                }}>
                    {
                        style => (

                            <section style={{
                                transform: `translateX(${style.x}px)`
                            }} className="projects">
                                <div className="grid projects__grid">
                                    {projects}
                                </div>
                            </section>
                        )
                    }

                </Motion>

            </Layout>
        )
    }
}

export default Projects